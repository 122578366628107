<template>
  <div class="community__wrapper">
    <div class="container">
      <div class="community__inner">
        <div class="community__header">Community</div>
        <div class="community__main">
          <Card
            v-for="card in cards"
            :key="card.id"
            :info="card"
            @click.native="$router.push('community/' + card.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from './Card'

export default {
  name: 'Community',
  components: {Card},
  data: () => ({
    cards: [
      {
        id: 1,
        tags: [
          {
            id: 1,
            name: 'Shop'
          },
          {
            id: 2,
            name: 'Farm'
          }
        ],
        title: 'How can I build the most competitive headgate?',
        comments: 23,
        date: '13 Sep 2020 at 14.21 PM',
        author: 'Burger with Bacon',
        photo: 'Burger',
        answers: [
          {
            id: 1,
            author: 'None',
            photo: 'None',
            date: '13 Sep 2020 at 14.21 PM',
            answer:
              "Use dragons! That’s what I did! There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
          },
          {
            id: 2,
            author: 'None',
            photo: 'None',
            date: '13 Sep 2020 at 14.21 PM',
            answer:
              "Use dragons! That’s what I did! There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc."
          }
        ]
      },
      {
        id: 2,
        tags: [
          {
            id: 1,
            name: 'Shop'
          }
        ],
        title: 'How can I build the most competitive headgate?',
        comments: 13,
        date: '9 Sep 2020 at 14.21 PM',
        author: 'Kiss Me',
        photo: 'Alex'
      },
      {
        id: 3,
        tags: [
          {
            id: 2,
            name: 'Farm'
          }
        ],
        title: 'How can I build the most competitive headgate?',
        comments: 123,
        date: '19 Sep 2020 at 14.21 PM',
        author: 'Who is This',
        photo: 'Andrew'
      }
    ]
  })
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
