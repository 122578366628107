<template>
  <div class="card__wrapper">
    <div class="card__tags">
      <div
        v-for="tag in info.tags"
        :key="tag.id"
        class="card__tag indicator"
        :class="{
          'indicator--blue indicator--fill': tag.name === 'Shop',
          'indicator--green indicator--fill': tag.name === 'Farm'
        }"
      >{{ tag.name }}</div>
    </div>
    <div class="card__title">{{ info.title }}</div>
    <div class="card__footer">
      <div class="card__info">
        <span class="card__photo" :style="{backgroundImage: 'url(' + image + ')'}"></span>
        <span class="card__name">{{ info.author }}</span>
      </div>
      <div class="card__comments">
        <span class="card__date">{{ info.date }}</span>
        <span class="card__line"></span>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.3">
            <path
              d="M12.6 0H1.4C1.0287 0 0.672601 0.1475 0.41005 0.41005C0.1475 0.672601 0 1.0287 0 1.4V14L2.8 11.2H12.6C12.9713 11.2 13.3274 11.0525 13.5899 10.7899C13.8525 10.5274 14 10.1713 14 9.8V1.4C14 0.623 13.37 0 12.6 0Z"
              fill="#172B4D"
            />
          </g>
        </svg>
        <span class="card__comment">{{ info.comments }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Andrew from '@/assets/icons/photo.jpeg'
import Alex from '@/assets/icons/photo2.jpg'
import Burger from '@/assets/icons/photo1.jpg'

export default {
  name: 'Community',
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    image: null
  }),
  created() {
    if (this.info.photo === 'Andrew') {
      this.image = Andrew
    } else if (this.info.photo === 'Alex') {
      this.image = Alex
    } else if (this.info.photo === 'Burger') {
      this.image = Burger
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style.scss';
</style>
